import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  title: string = 'FatalDream';

  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.typewriter();
  }

  //#region typewriter
  async sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  async typewriter() {
    let text = this.title;
    this.title = '';

    await this.write(text);
    while (true) {
      this.titleService.setTitle(this.title);
      await this.sleep(5000);
      await this.remove(5);
      await this.write('Merlin');
      this.titleService.setTitle(this.title);
      await this.sleep(5000);
      await this.remove(6);
      await this.write('Dream');
    }
  }

  async write(text) {
    while (text.length > 0) {
      this.title += text.slice(0, 1);
      text = text.slice(1);
      await this.sleep(200);
    }
  }

  async remove(count: number) {
    while (count-- > 0) {
      this.title = this.title.slice(0, -1);
      await this.sleep(200);
    }
  }
  //#endregion

  buttonClickHandler(event: Event) {
    let element = event.target as Element;
    let url = element.attributes.getNamedItem('href').value;
    window.location.href = url;
  }
}
