<div class="container" style="height: 100vh">
  <div class="title-row">
    <div class="box">
      <h1 class="title typewriter">{{ title }}</h1>
      <h1 class="title caret">_</h1>
    </div>
  </div>
  <div class="row">
    <div
      class="col-6"
      style="
        background-color: #272a2c;
        padding: 2em 0;
        border-right: 1px solid #35393b;
      "
    >
      <div class="col-12">
        <div class="mb-3" style="font-size: 1.5em">Applications</div>
      </div>
      <div class="col-12">
        <button class="button" (click)="buttonClickHandler($event)">
          <img src="assets/gitlab-icon-rgb.svg" />GitLab
          <a href="https://lab.fataldream.com"></a>
        </button>
        <button class="button" (click)="buttonClickHandler($event)">
          <img src="assets/jira-software-blue.svg" />Jira
          <a href="https://jira.fataldream.com"></a>
        </button>
      </div>
    </div>

    <div
      class="col-6"
      style="
        background-color: #272a2c;
        padding: 2em 0;
        border-left: 1px solid #35393b;
      "
    >
      <div class="col-12">
        <div class="mb-3" style="font-size: 1.5em">My Profiles</div>
      </div>
      <div class="col-12">
        <button class="button" (click)="buttonClickHandler($event)">
          <img src="assets/github-mark-white.png" />GitHub
          <a href="https://github.com/fatalmerlin"></a>
        </button>
        <button class="button" (click)="buttonClickHandler($event)">
          <img src="assets/li-in-bug.png" />LinkedIn
          <a href="https://www.linkedin.com/in/merlinbrandes"></a>
        </button>
      </div>
    </div>
  </div>
</div>
